import { graphql, PageProps } from 'gatsby'

import PricesSection from '../sections/PricesSection'

const PrestationsIndex = ({ data }: PageProps<Queries.PricesPageQuery>) => {
  return <PricesSection data={data} />
}

export default PrestationsIndex

export const query = graphql`
  query PricesPage {
    allStrapiService {
      edges {
        node {
          id
          index
          title
          path
          startingPriceSentence
        }
      }
    }
    strapiPricesPage {
      pricesDescription {
        data {
          pricesDescription
        }
      }
      seo {
        description
        title
      }
    }
  }
`
