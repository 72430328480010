import { Link, PageProps } from 'gatsby'
import styled from 'styled-components'

import MarkdownWrapper from '../../components/MarkdownWrapper'
import Layout from '../../layouts'

import {
  Card,
  Container,
  Desc,
  Header,
  PrestationBlock,
  Text,
  Title
} from './PricesSection.styles'

const PricesSectionCard = styled(Card)`
  padding-bottom: 35px;
  margin-bottom: 35px;
`

const PricesSection = ({
  data: {
    allStrapiService: { edges },
    strapiPricesPage
  }
}: Pick<PageProps<Queries.PricesPageQuery>, 'data'>) => (
  <Layout
    smallNavBar
    seo={{
      title: strapiPricesPage?.seo?.title,
      description: strapiPricesPage?.seo?.description
    }}
  >
    <Container>
      <Title>
        Tarifs: maquilleuse professionnelle à Nantes et ses environs
      </Title>
      <PricesSectionCard>
        <Desc>
          <MarkdownWrapper>
            {strapiPricesPage?.pricesDescription?.data?.pricesDescription!}
          </MarkdownWrapper>
        </Desc>
        <div style={{ width: '100%', marginTop: 35 }}>
          {[...edges]
            .sort((a, b) => Number(a.node.index) - Number(b.node.index))
            .map(({ node: { id, title, startingPriceSentence, path } }) => (
              <PrestationBlock key={id}>
                <Header>{title}&nbsp;:</Header>
                <Text>{startingPriceSentence}</Text>
                <Link to={`/prestations/${path}/`}>Voir les prestations</Link>
              </PrestationBlock>
            ))}
        </div>
      </PricesSectionCard>
    </Container>
  </Layout>
)

export default PricesSection
