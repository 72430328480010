import styled from 'styled-components'

import { ContainerCard, TitleLarge } from '../../common/styles'
import MarkdownWrapper from '../../components/MarkdownWrapper'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled(TitleLarge)`
  margin-top: 28px;
  padding-right: 7px;
  padding-left: 7px;
`

export const Card = styled(ContainerCard)`
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const Desc = styled.div`
  padding-bottom: 21px;
  border-bottom: dotted 1px black;
`

export const PrestationBlock = styled.div`
  &:not(:first-child) {
    margin-top: 14px;
  }

  &:not(:last-child) {
    margin-bottom: 35px;
  }
`

export const Header = styled.span`
  display: block;
  font-family: Poppins;
  font-size: 14px;
  margin-bottom: 14px;
  line-height: 21px;
  font-weight: 400;
`

export const Text = styled.p`
  line-height: 21px;
  margin-bottom: 14px;
`
